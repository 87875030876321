.steps_title {
    height:50px;
    background-color: #f0f2f5;
    .ant-steps-item-finish{
        line-height: 60px;
        padding-left: 100px !important;
        .ant-steps-item-icon{
            border-color: #67c23a;
            font-size:12px;
            height: 16px;
            width: 16px;
            border-radius: 16px;
            line-height: 16px;
            vertical-align: initial;
            .ant-steps-icon {
                color: #67c23a !important;
            }
        }
        .ant-steps-item-title{
            color: #67c23a !important;
        }
    }
}