@media screen and (min-width:1599px) {
  .Login_main {
    transform: translate(-50%, -50%) scale(.7);
  }
}

.Login_body {
  width: 100%;
  height: 100vh;
  background: url(./img/bg_big.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .Login_title {
    position: fixed;
    width: 40%;
    height: 30%;
    margin-top: 3%;
    margin-left: 4%;
    z-index: 10;

    .Login_title_one {
      font-size: 2.5vw;
      font-weight: bolder;
      letter-spacing: 5px;
      margin-bottom: 10px;
      color: #fff
    }

    .Login_title_two {
      font-size: 2.5vw;
      letter-spacing: 1px;
      color: #fff;
      width: 30vw;

    }
  }

  .Login_title_footer {
    text-align: center;
    margin-top: 18%;
    font-size: 14px;
    color: white;
    font-weight: 400;

    .Login_title_footer_div {
      width: 400px;
      margin: 0 auto;
      padding: 20px 0;

      .Login_title_footer_div_a {
        // display:inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
      }

      .Login_title_footer_div_img {
        width: 20px;
        height: 20px;
        text-align: center;
        background: url(./img/beian.png);
        margin-left: 80px;
        float: left;
      }

      .Login_title_footer_div_p {
        text-align: center;
        width: 300px;
        // float:left;
        height: 20px;
        line-height: 20px;
        margin: 0px 0px 0px 5px;
        color: #939393;
      }

      .Login_title_footer_div_p1 {
        text-align: center;
        // min-width: 300px;
        // float:left;
        height: 20px;
        line-height: 20px;
        margin: 0px 0px 0px 5px;
        color: #939393;
      }
    }

    .Login_title_footer_div {
      text-align: center
    }
  }


  .ant-input-group-addon {
    padding: 0;
  }

  .Login_main {
    width: 65vw;
    height: 35vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(./img/bg_blue.png) no-repeat;
    background-size: cover;



    .Login_put {
      width: 27vw;
      height: 100%;

      // @media screen and (max-width: 1500px) {
      //   width: 360px;
      //   height: 450px
      // }

      //background: #000000;
      position: fixed;
      right: 0%;
      border-radius: 5px;


      :global {

        .username {
          width: 1.5vw;
          height: 1.8vw;
          background-size: cover !important;
          background: url(./img/name.png) no-repeat;
        }

        .paw {
          width: 1.5vw;
          height: 1.8vw;
          background-size: cover !important;
          background: url(./img/paw.png) no-repeat;
        }

        .ipbefore {
          width: 1.8vw;
          height: 1.6vw;
          background-size: cover !important;
          background: url(./img/ipbefore.png) center no-repeat;
        }

        .vercode {
          width: 1.6vw;
          height: 1.8vw;
          background-size: cover !important;
          background: url(./img/ver.png) center no-repeat;
        }

        .ant-input-affix-wrapper {
          height: 3.8vw;
          background: #f8f8f8 !important;
          border: 0px solid #f8f8f8;
          border-radius: 10px;
        }


        .ant-input {
          background: #f8f8f8 !important;
          border: 0px solid #f8f8f8;
        }

        .ant-btn-primary {
          height: 4.2vw;
          border-radius: 6px;
          background: linear-gradient(-90deg, #2F80ED 0%, #40AAF6 100%);
          box-shadow: 0px 6px 9px 0px rgba(61, 163, 244, 0.32);

          span {
            margin-right: 1.5vw;
            margin-left: 1.5vw;
          }
        }

        .ant-btn-lg {
          font-size: 1.5vw;
        }

        .ant-btn-lg {
          font-size: 1.5vw !important;
        }

        //.ant-btn {
        //  font-size: 20px;
        //  border: 0px solid rgb(230, 230, 230) !important;
        //}
        //
        //.ant-btn-link {
        //  color: #268BFE !important;
        //}
        //.ant-btn-block {
        //  font-size: 28px !important;
        //}
        //
        //.ant-btn-sm {
        //  font-size: 22px;
        //}

        //.sverify-box{
        //  width: 21vw;
        //}

        .simple-verify{
          //width: 240px !important;
          //width: 40% !important;
          height: 3.5vw !important;
        }

        .verify-bar {
          border-radius: 6px;

        }

        .simple-verify .verify-box {
          //width: 300px;
          //height: 80px;
        }

        .simple-verify .verify-bar {

        }

        .simple-verify .verify-bar .icon {
          height:  3vw;
          width: 3vw;
          background-image: url(./img/huakuai.png) !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          //background-color: red !important;
          background-position: center !important;

        }

        .simple-verify .verify-tips {
          font-size: 1.25vw;
        }

        .verify-icon {
          //height: 4vw;
          //width: 4vw;
          //background-image: url(./img/huakuai.png) !important;
          //background-repeat: no-repeat !important;
          //background-size: cover !important;

        }

        .ant-input-group .ant-input {
          height: 7vh;
        }

        // .ant-input-group-addon {
        //   border: '';
        // }

        .ant-input {
          text-indent: 1em;
        }

        //.ant-form-item{
        //  margin-bottom: 1vw;
        //}

        .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
        .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
        .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
        .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
          border-color: #f8f8f8;
        }
      }

      .Login_logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.7vh;
        margin-bottom: 2.7vh;


        // background: url(./img/logo2.png) no-repeat;
        // background-size:70% 50%;
        // background-position: center;
        .Login_logo_img {
          width: auto;//96
          height: 5vw;
          object-fit:contain;

        }

        .hideImage{
          display: none;
        }

        .Login_logo_h2 {
          text-align: center;
          align-self: center;

          line-height: 5vw;
          margin-left: 15px;
          font-size: 2.5vw;
          font-weight: bold;
        }

        //.ant-form-item-control-input-content {
        //  height: 74px;
        //}


      }

      .Login_form {
        justify-content: center;
        align-items: center;

      }

      .btn-login{
        //margin-top: 30px;
      }


    }
  }


  .Home_title_footer {
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    text-align: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    font-size: 14px;
    color: white;
    font-weight: 400;

    .Home_title_footer_div {
      width: 300px;
      margin: 0 auto;
      //  padding:20px 0;
      .Home_title_footer_div_a {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
      }

      .Home_title_footer_div_img {
        width: 20px;
        height: 20px;
        background: url(./img/beian.png);
        margin-left: 18px;
        float: left;
      }

      .Home_title_footer_div_p {
        text-align: center;
        // float:left;
        height: 20px;
        line-height: 20px;
        margin: 0px 0px 0px 5px;
        color: #939393;
      }
    }

    .Home_title_footer_div {
      text-align: center
    }
  }

}
