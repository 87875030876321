.searchTotal{
    margin-left: 60%;
    @media screen and (max-width: 1680px){
        margin-left: 40%;
    }
}
.searchTime{
    width:25%;
    margin-left: 60%;
    @media screen and (max-width: 1680px){
        margin-left: 40%;
        width:40%;
    }
}
.searchButton{
    width:15%;
    @media screen and (max-width: 1680px){
        width:20%
    }
}
.footer{
    @media screen and (max-width: 1680px){
        padding:60px 50px 0px
    }
}