.Home_body {
  width: 100%;
  height: 100vh;

  .Home_body_logo {
    height: 32px;
    width: 168px;
    margin: 16px;
    background: url('./img/logodb.png') no-repeat;
    background-size: 100% 100%;
  }

  .Home_body_logo1 {
    height: 32px;
    //width: 32px;
    width: auto;
    object-fit:contain;
    // margin: 12px 6px 12px 20px;
    // float: left;
    // background: url('./img/logo3.png') center center no-repeat;
    // background-size: 100% 100%;
  }

  .hideImage {
    display: none;
  }

  .Home_body_title {
    display: flex;
    justify-content: center;
    padding-top: 10px
  }

  .Home_body_h2 {
    // float: left;
    color: white;
    // margin: 12px 0px;
    text-align: center;
    min-width: 100px;
    line-height: 32px
  }

  .Home_body_right_header {
    background: #fff;
    padding: 0;
    height: 64px;

    .hearder_label {
      width: 100%;
      @media screen and (max-width: 1550px) {
        width: 100%
      }
    }
  }

  .Home_body_right_context {
    height: calc(~"100% - 50px");
    background: #fff;
    padding-bottom: 20px;
    overflow-y: auto;

  }

  .Home_body_right_header_right_context_msg:hover {
    cursor: pointer;
    color: #1890ff;
  }

  .Home_body_right_header_trigger:hover {
    color: #1890ff;
  }

}

.Home_title_footer {
  text-align: center;

  font-size: 14px;
  color: white;
  font-weight: 400;

  .Home_title_footer_div {
    width: 300px;
    margin: 0 auto;
    //  padding:20px 0;
    .Home_title_footer_div_a {
      display: inline-block;
      text-decoration: none;
      height: 20px;
      line-height: 20px;
    }

    .Home_title_footer_div_img {
      width: 20px;
      height: 20px;
      background: url(./img/beian.png);
      margin-left: 18px;
      float: left;
    }

    .Home_title_footer_div_p {
      text-align: center;
      // float:left;
      height: 20px;
      line-height: 20px;
      margin: 0px 0px 0px 5px;
      color: #939393;
    }
  }

  .Home_title_footer_div {
    text-align: center
  }
}
