.steps-content{
    height: 500px;
    padding: 30px;
}
.steps-content .ant-checkbox-wrapper{
    width: 300px;
    border: 1px solid #dcdfe6;
    padding: 5px 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper{
    margin-left: 0px  !important;

}