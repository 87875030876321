.HomePage {
  width: 100%;
  height: 100%;

  .HomePage_header {
    width: 100%;
    height: 70px;
  }

  .HomePage_context {
    width: 100%;
    height: 170px;
  }

  .HomePage_calculator {
    widows: 100%;
    height: 80px;
    border: 1px solid gainsboro;
    padding: 20px 25px;

    .HomePage_titles {
      border-left: 7px solid #42b1fe;
      font-size: 25px;
      padding-left: 20px;
      color: #42b1fe;
    }

    .HomePage_bty {
      background-color: #ff9b21;
      margin-left: 12px;
      color: #fff;
      border-radius: 5px;
    }
  }

  .HomePage_footer {
    width: 100%;
    height: 360px;
    margin-bottom: 20px;
    border-left: 1px solid gainsboro;
    border-right: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    @media screen and (max-width: 1550px) {
      height: 300px
    }

    .HomePage_footer_left {
      float: left;
      width: 50%;
      height: 100%;
      padding: 60px 100px 0 100px;
      @media screen and (max-width: 1500px) {
        padding: 30px 80px 0px
      }
      border-right: 1px solid gainsboro;
    }

    .HomePage_footer_right {
      float: left;
      width: 50%;
      padding: 30px 100px 0 100px;
      height: 100%;
      @media screen and (max-width: 1500px) {
        padding: 30px 80px 0px
      }
    }

    .HomePage_footer_right2 {
      float: left;
      width: 50%;
      padding: 0px 100px 0 100px;
      height: 100%;
      @media screen and (max-width: 1500px) {
        padding: 30px 80px 0px
      }
    }

    .HomePage_footer_left_P {
      font-size: 40px;
      color: black;
    }

    .HomePage_footer_left_P1 {
      color: #fbab5f;
      font-size: 60px;
      text-align: center;
    }

    .HomePage_footer_left_P2 {
      font-size: 20px;
    }
  }

}